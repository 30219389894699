import { useState, useEffect } from "react";
import About from "./components/About";
import Footer from "./components/Footer";
import Gallery from "./components/Gallery";
import Hero from "./components/Hero";
import NavBar from "./components/NavBar";
import Offer from "./components/Offer";
import Partners from "./components/Partners";
import Services from "./components/Services";

function App() {
    const [opacity, setOpacity] = useState(0);
    const [opacitySecond, setOpacitySecond] = useState(0);
    const [opacityThird, setOpacityThird] = useState(0);

    useEffect(() => {
        window.addEventListener('beforeinstallprompt', (event) => {
            // Prevent the mini-infobar from appearing on mobile
            event.preventDefault();
            // Stash the event so it can be triggered later.
            window.deferredPrompt = event;
            // Update the install UI to notify the user app can be installed
            //showInstallPromotion();
        });

        window.addEventListener('appinstalled', () => {
            // Hide the app-provided install promotion
            //hideInstallPromotion();
            window.deferredPrompt = null;
        });
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setOpacity(1);
            setTimeout(() => {
                setOpacitySecond(1);
                setTimeout(() => {
                    setOpacityThird(1);
                }, 300);
            }, 400);
        }, 500);
    }, []);

    return (
        <>
            <div className="overflow-hidden transition-all ease-in-out duration-300" style={{ opacity: opacity }}>
                <NavBar />
                {/* You can add the install button here if you want */}
            </div>
            <div className="overflow-hidden transition-all ease-in-out duration-300" style={{ opacity: opacitySecond }}>
                <Hero />
            </div>
            <div className="overflow-hidden transition-all ease-in-out duration-300" style={{ opacity: opacityThird }}>
                <Offer />
                <Services />
                <Gallery />
                <About />
                <Partners />
                <Footer />
            </div>
        </>
    );
}

export default App;