import { useEffect } from "react";
import { useState } from "react";

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
const getElementsById = (id) => {
    return [...document.getElementsByTagName('*')].filter(element => element.id === id)
}

const Gallery = () => {
    const colours = ["red", "yellow", "orange", "pink", "purple", "blue", "cyan", "lightgreen", "green", "black"];
    const [sources, setSources] = useState([]);

    const changeImageSource = () => {
        const temp = [];
        while(temp.length < 4) {
            const index = getRandomInt(0, 9),
                colourIndex = getRandomInt(1, 2);
            
            if(temp.includes(`/belts/${colours[index]}-${colourIndex}.webp`)) continue;
            temp.push(`/belts/${colours[index]}-${colourIndex}.webp`);
        }
        setSources(temp);
        changeImageVisibility(true);
    }

    useEffect(() => {
        const temp = [];
        while(temp.length < 4) {
            const index = getRandomInt(0, 9),
                colourIndex = getRandomInt(1, 2);
            
            if(temp.includes(`/belts/${colours[index]}-${colourIndex}.webp`)) continue;
            temp.push(`/belts/${colours[index]}-${colourIndex}.webp`);
        }
        setSources(temp);
        changeImageVisibility(true);
    }, [])

    const changeImageVisibility = (state) => {
        const images = getElementsById('gall');
        images.forEach((image, index) => {
            setTimeout(() => {
                image.style.opacity = !state ? "0" : "1";
            }, 200 * index);
        });

        if(state) {
            setTimeout(() => {
                changeImageVisibility(false);
                setTimeout(() => {
                    changeImageSource();
                }, 1500);
            }, 3000);
        }
    }

    return (
    <div className="w-full flex flex-col items-center gap-14 py-14 border-b-[1px] border-[#353535] px-5" id="gallery">
        <h1 className="text-white text-5xl font-corporate small-caps font-semibold tracking-[15px] mr-[-15px]">galerija</h1>
        <div className="w-full">
            <div className="w-full justify-center flex flex-wrap gap-5 xl:gap-[10rem]">
                <img className={`'max-w-[640px] max-h-[800px] mt-5 xl:mt-[5rem]'} rounded-md transition all ease-in duration-300 opacity-0`} src={sources[0]} id="gall" />
                <img className={`'max-w-[640px] max-h-[800px] xl:mt-[10rem]'} rounded-md transition all ease-in duration-300 opacity-0`} src={sources[1]} id="gall" />
                <img className={`'max-w-[640px] max-h-[800px] mt-5 xl:mt-[5rem]'} rounded-md transition all ease-in duration-300 opacity-0`} src={sources[2]} id="gall" />
                <img className={`'max-w-[640px] max-h-[800px] xl:mt-[10rem]'} rounded-md transition all ease-in duration-300 opacity-0`} src={sources[3]} id="gall" />
            </div>
        </div>
    </div>
  )
}

export default Gallery