import { Button } from "@mantine/core";
import { notifications } from '@mantine/notifications';
import classes from './Services.module.css';
import video from '../assets/LIAR_FINAL.MP4';

const Services = () => {
  
  const handleClick = () => {
    // Prikaz notifikacije
    notifications.show({
      title: 'Kontaktiraj nas',
      message: 'Broj telefona: 063/464-094',
      color: '#ffe71c',
      autoClose: false,
      classNames: classes,
    });

    // Otvaranje telefonske aplikacije
    window.location.href = 'tel:+38163464094'; // Zameni sa stvarnim brojem telefona
  };

  return (
    <div className="w-full flex flex-col items-center gap-14 py-14 px-5 border-b-[1px] border-[#353535] bg-[#1c1c1c] overflow-hidden h-[800px] sm:h-[650px] md:h-[550px]" id="services">
      <div className="absolute mt-[-3.5rem] w-full h-[800px] sm:h-[650px] md:h-[550px] opacity-20">
        <video
          className="w-full h-[800px] sm:h-[650px] md:h-[550px] object-cover"
          src={video}
          autoPlay
          loop
          playsInline
          muted />
      </div>
      <h1 className="text-white text-5xl font-corporate small-caps font-semibold tracking-[15px] mr-[-15px]">usluge</h1>
      <p className="text-center max-w-7xl text-lg text-white opacity-[0.6] font-light">
        Zamena sigurnosnih auto pojaseva u boji podrazumeva zamenom nove trake na vas postojeci mehanizam.
        <br />
        Funkcionalnost vaseg mehanizma se ne menja. Sve komponente ostaju vase/iste, samo je traka (pojas) nasa.
        <br />
        Naravno, ako ste daleko postoji mogucnost da vi skinete vas mehanizam i posaljete nam, u istom danu menjamo traku i saljemo vam nazad. Isto tako mozete poruciti samo trake(pojas) u boji po zelji.  
        <br />
        Pojasevi su standard i univerzalni za mehanizme svih vozila.
        <br />
        Sirina 4,8 mm
        <br />
        Debljina 1,5 mm
        <br />
        Duzina 3,6 m
      </p>

      <Button
        onClick={handleClick}
        color="#ffe71c"
        className="!text-[#181818] !font-bold"
      >
        Kontaktiraj nas
      </Button>
    </div>
  )
}

export default Services;
