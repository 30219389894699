import { ActionIcon, Button, Input } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useState } from "react";/*
const checkFileExists = async (filePath) => {
    try {
      const response = await fetch(filePath);
  
      // The fetch won't reject in case of HTTP 404 status, so we need to handle it manually
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };*/
const Offer = () => {
    const [selectedColour, setSelectedColour] = useState({name: 'red', hex: '#FF0000'});
    const [selectedType, setSelectedType] = useState("boje");
    const [prompt, setPrompt] = useState('');
    const [search, setSearch] = useState('');
    const colours = [
        {type: 'boje', content: ["crven", "crveno", "crvena", "red"], name: 'red', hex: '#FF0000', className: ''},
        {type: 'boje', content: ["žuta", "žuto", "žut", "yellow"], name: 'yellow', hex: '#FFFF00', className: selectedType === "dezeni" ? 'hidden' : ''},
        {type: 'boje', content: ["roza", "rozo", "pink"], name: 'pink', hex: '#ff00b8', className: selectedType === "dezeni" ? 'hidden' : ''},
        {type: 'boje', content: ["plava", "plavo", "blue"], name: 'blue', hex: '#0000FF', className: ''},
        {type: 'boje', content: ["svetlo zelena", "svetlo zeleno", "light green"], name: 'lightgreen', hex: '#00ff87', className: selectedType === "dezeni" ? 'hidden' : ''},
        {type: 'boje', content: ["zelena", "zeleno", "green"], name: 'green', hex: '#45fe00', className: selectedType === "dezeni" ? 'hidden' : ''},
        {type: 'boje', content: ["bela", "belo", "white"], name: 'white', hex: '#FFFFFF', className: ''},

        {type: 'dezeni', content: ["ljubičasta", "ljubičasto", "purple"], name: 'purple', hex: '#6f00ff', className: selectedType === "boje" ? 'hidden' : selectedType === "dezeni" ? 'hidden' : ''},
        {type: 'dezeni', content: ["crna", "crno", "black"], name: 'black', hex: '#000000', className: selectedType === "boje" ? 'hidden' : ''},
        {type: 'dezeni', content: ["cijan", "cijano", "cyan"], name: 'cyan', hex: '#00b0fb', className: selectedType === "boje" ? 'hidden' : ''},
        {type: 'dezeni', content: ["narandžasta", "narandžasto", "orange"], name: 'orange', hex: '#FFA500', className: selectedType === "boje" ? 'hidden' : selectedType === "dezeni" ? 'hidden' : ''},
        {type: 'dezeni', content: ["offwhite", "off white", "off"], name: 'offwhite', hex: '#0a0a0a', className: selectedType === "boje" ? 'hidden' : '', border: 'border-[3px] border-inside border-[#FFFF00] border-solid'},
        {type: 'dezeni', content: ["gold",], name: 'gold', hex: '#0b0b0b', className: selectedType === "boje" ? 'hidden' : '', border: 'border-[3px] border-inside border-[#efbf00] border-solid'},
        {type: 'dezeni', content: ["m-blue", "bmw", "m", "plava"], name: 'm-blue', hex: '#374fef', className: selectedType === "boje" ? 'hidden' : '', border: 'border-[3px] border-inside border-[#00000077] border-solid'},
        {type: 'dezeni', content: ["m-red", "bmw", "m", "crvena"], name: 'm-red', hex: '#EE0000', className: selectedType === "boje" ? 'hidden' : '', border: 'border-[3px] border-inside border-[#00000099] border-solid'},
        {type: 'dezeni', content: ["m-yellow", "bmw", "m", "žuta"], name: 'm-yellow', hex: '#FFFE00', className: selectedType === "boje" ? 'hidden' : '', border: 'border-[3px] border-inside border-[#00000077] border-solid'},
        {type: 'dezeni', content: ["red-black", "crveno-crna", "crveno crna", "crvena", "crna"], name: 'red-black', hex: '#FE0000', className: selectedType === "boje" ? 'hidden' : '', border: 'border-[3px] border-inside border-[#00000077] border-solid'},
        {type: 'dezeni', content: ["crno-crvena", "crno", "crvena"], name: 'crno-crvena', hex: '#0a0a09', className: selectedType === "boje" ? 'hidden' : '', border: 'border-[3px] border-inside border-[#FE0000] border-solid'},
        {type: 'dezeni', content: ["crno-plava", "crno", "plava"], name: 'crno-plava', hex: '#0a0a08', className: selectedType === "boje" ? 'hidden' : '', border: 'border-[3px] border-inside border-[#374fef] border-solid'},
        {type: 'dezeni', content: ["crno-zuta", "crno", "zuta"], name: 'crno-zuta', hex: '#0a0a07', className: selectedType === "boje" ? 'hidden' : '', border: 'border-[3px] border-inside border-[#FFFF00] border-solid'},
        {type: 'dezeni', content: ["guchi-red", "crno", "crvena"], name: 'guchi-red', hex: '#0a0a06', className: selectedType === "boje" ? 'hidden' : '', border: 'border-[3px] border-inside border-[#FE0000] border-solid'},
        {type: 'dezeni', content: ["italia", "crno", "zelena"], name: 'italia', hex: '#0a0a05', className: selectedType === "boje" ? 'hidden' : '', border: 'border-[3px] border-inside border-[#45fe00] border-solid'},
        {type: 'dezeni', content: ["karirana-plava", "crno", "plava"], name: 'karirana-plava', hex: '#0a0a04', className: selectedType === "boje" ? 'hidden' : '', border: 'border-[3px] border-inside border-[#374fef] border-solid'},
        {type: 'dezeni', content: ["karirana-zuta", "crno", "zuta"], name: 'karirana-zuta', hex: '#0a0a03', className: selectedType === "boje" ? 'hidden' : '', border: 'border-[3px] border-inside border-[#FFFF00] border-solid'},
        {type: 'dezeni', content: ["maskirna-zelena", "zeleno", "belo"], name: 'maskirna-zelena', hex: '#0a0a02', className: selectedType === "boje" ? 'hidden' : '', border: 'border-[3px] border-inside border-[#FFFFFF] border-solid'},
        {type: 'dezeni', content: ["rline-blue", "crna", "plava"], name: 'rline-blue', hex: '#0a0a01', className: selectedType === "boje" ? 'hidden' : '', border: 'border-[3px] border-inside border-[#374fef] border-solid'},
        {type: 'dezeni', content: ["zeleno-crvena", "zeleno", "crvena"], name: 'zeleno-crvena', hex: '#58CF8A', className: selectedType === "boje" ? 'hidden' : '', border: 'border-[3px] border-inside border-[#FE0000] border-solid'},

    ];

    const renderImages = (type) => {
        if (type === "kompletna") {
            return Array.from({ length: 34 }, (_, i) => i + 1).map((num) => (
                <img 
                    key={num}
                    src={`/belts/kompletna/${num}.jpeg`} 
                    className="sm:w-[50%] 4xl:w-full rounded-md" 
                    alt={`Kompletna Image ${num}`} 
                />
            ));
        }
        return colours.filter((colour) => colour.content.includes(search.toLowerCase())).map((colour) => (
            <div className="flex w-full gap-5 flex-col sm:flex-row sm:justify-center" key={colour.hex}>
                <img src={`/belts/${colour.type}/${colour.name}.jpeg`} className="sm:w-[50%] 4xl:w-full rounded-md" />
            </div>
        ));
    };

    return (
        <div className="w-full flex flex-col items-center gap-14 py-14 border-b-[1px] border-[#353535] overflow-hidden px-5" id="offer">
            <div className="flex flex-col sm:flex-row xl:w-[50%] mx-auto gap-1 sm:gap-10">
                <Button
                    justify="space-between"
                    className=""
                    leftSection={<span />}
                    rightSection={<span />}
                    onClick={() => {setSelectedType("boje"); setSearch(null); setSelectedColour({content: ["crven", "crveno", "crvena", "red"], name: 'red', hex: '#FF0000', className: ''})}}
                    fullWidth
                    color="#2C2E33"
                    mt="md"
                >
                    Boje
                </Button>
                <Button
                    justify="space-between"
                    className=""
                    leftSection={<span />}
                    rightSection={<span />}
                    onClick={() => {setSelectedType("dezeni"); setSearch(null); setSelectedColour({content: ["crven", "crveno", "crvena", "red"], name: 'red', hex: '#FF0000', className: ''})}}
                    fullWidth
                    color="#2C2E33"
                    mt="md"
                >
                    Dezeni
                </Button>

                <Button
                    justify="space-between"
                    className=""
                    leftSection={<span />}
                    rightSection={<span />}
                    onClick={() => {setSelectedType("kompletna"); setSearch(null); setSelectedColour({name: 'kompletna', hex: '#FFFFFF', className: '', folder: 'kompletna'})}}
                    fullWidth
                    color="#2C2E33"
                    mt="md"
                >
                    Kompletna Ponuda
                </Button>

                <div className="w-full flex gap-3">
                    <Input
                        icon={"@"}
                        fullWidth
                        className="w-full"
                        variant="filled"
                        leftSection={<img src="/socials/search.svg" className="h-[16px] invert-[0.4]" />}
                        placeholder="Primer: crna"
                        onChange={(e) => setPrompt(e.target.value)}
                        classNames={{
                            input: "!bg-[#2C2E33]",
                        }}
                        mt="md"
                    />
                    <ActionIcon onClick={() => {

                        if (colours.filter((colour) => colour.content.includes(prompt.toLowerCase())).length > 0) {
                            return setSearch(prompt);
                        }

                        notifications.show({
                          title: 'Nema rezultata',
                          message: 'Za tu pretragu ne postoji niti jedan rezultat',
                          color: '#ffe71c',
                          autoClose: true,
                        });
                        
                        setSelectedType("boje");
                        setSearch(null);
                        return setSelectedColour({content: ["crven", "crveno", "crvena", "red"], name: 'red', hex: '#FF0000', className: ''})

                    }} color="#ffe71c" mt="md" size="lg"><p className="text-[10px] text-[#181818] font-bold">GO</p></ActionIcon>
                </div>
            </div>
            <h1 className="hidden text-white text-5xl font-corporate small-caps font-semibold tracking-[15px] mr-[-15px]">odaberi iz ponude</h1>
        <div className="flex flex-wrap gap-5 min-h-[150px] rounded-md justify-center">
            {selectedType !== "kompletna" && colours.map((colour) => (
                <div key={colour.hex} className={`flex flex-col gap-5 ${colour.className}`}>
                    <div onClick={() => setSelectedColour(colour)} className={`w-[30px] h-[30px] sm:w-[40px] sm:h-[40px] cursor-pointer ${colour?.border && colour.border}`} style={{ backgroundColor: colour.hex }}></div>
                    <div className={`transition-all w-[30px] sm:w-[40px]`} style={{ backgroundColor: selectedColour.hex === colour.hex ? colour.hex : '#1c1c1c', height: selectedColour.hex === colour.hex ? '90px' : '0px' }}></div>
                </div>
            ))}
        </div>
        {search || selectedType === "kompletna" ? 
            renderImages(selectedType)
            :
            <div className="flex w-full gap-5 flex-col sm:flex-row sm:justify-center">
                <img src={`/belts/${selectedType}/${selectedColour.name}.jpeg`} className="sm:w-[50%] 4xl:w-full rounded-md" />
                {selectedType !== "dezeni" && <img src={`/belts/${selectedColour.name}-2.webp`} className="sm:w-[50%] 4xl:w-full rounded-md" />}
            </div>
        }
    </div>
);
}

export default Offer;