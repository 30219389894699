const Footer = () => {
    return (
        <div className='w-full flex flex-col gap-4 items-center justify-center py-10 border-b-[1px] border-[#353535]'
             id="footer">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2834.170732125417!2d20.588048976695415!3d44.7365334816941!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a76497ed227c5%3A0xd3f83e128fed212a!2zS3J1xb5uaSBQdXQgNDgsIExlxaF0YW5lLCBTZXJiaWE!5e0!3m2!1sen!2sba!4v1717681905247!5m2!1sen!2sba"
                height="250" className="w-full px-5 md:px-[10rem] border-[0px]" allowfullscreen=""
                loading="lazy"></iframe>
            <div className="flex justify-between w-full px-5 md:px-[10rem] text-white text-lg pt-2">
                <p className="w-[33%] text-right flex justify-end">Kružni put 48, Leštane</p>
                <p>Beograd</p>
                <p className="w-[33%] flex justify-start">Srbija 11000</p>
            </div>
            <div className="flex gap-[3rem] invert pt-[1rem]">
                <a href="https://www.facebook.com/auto.pojasevi.shop/?locale=sr_RS" target="_blank" rel="noreferrer">
                    <img src="/socials/facebook.svg" className="h-[30px]" alt="Facebook"/>
                </a>
                <a href="https://www.instagram.com/autopojasevi.rs/" target="_blank" rel="noreferrer">
                    <img src="/socials/instagram.svg" className="h-[30px]" alt="Instagram"/>
                </a>
                <a href="https://www.tiktok.com/@autopojasevi.rs" target="_blank" rel="noreferrer">
                    <img src="/socials/tiktok.svg" className="h-[30px]" alt="TikTok"/>
                </a>
                <a href="https://kupujemprodajem.com/automobili-oprema/obavezna-oprema/auto-pojasevi-u-boji-autoliv-original/oglas/145109330?utm_source=kp&utm_medium=interfejs&utm_campaign=social_share&utm_content=oglas" target="_blank" rel="noreferrer">
                    <img src="/socials/kupujemprodajem.svg" className="h-[30px]" alt="TikTok"/>
                </a>
            </div>
        </div>
    )
}

export default Footer