import classes from './Partners.module.css'

const Partners = () => {
  return (
    <div className="w-full flex flex-col items-center py-14 border-b-[1px] border-[#353535]" id="partners">
        <h1 className="text-white text-5xl font-corporate small-caps font-semibold tracking-[15px] mr-[-15px]">partneri</h1>
        <div className={classes.wrapper}>
          <div className={`${classes.item} ${classes.item1}`}><img src="/partners/arceowheels.webp" className="h-[80%]" /></div>
          <div className={`${classes.item} ${classes.item2}`}><img src="/partners/customgarage.webp" className="h-[80%]" /></div>
          <div className={`${classes.item} ${classes.item3}`}><img src="/partners/arceoserbia.webp" className="h-[80%]" /></div>
          <div className={`${classes.item} ${classes.item4}`}><img src="/partners/odmetnicipodcast.png" className="h-[80%]" /></div>
          <div className={`${classes.item} ${classes.item5}`}><img src="/partners/atlas24.svg" className="h-[80%]" /></div>
          <div className={`${classes.item} ${classes.item6}`}><img src="/partners/eneos.jpg" className="h-[80%]" /></div>
          <div className={`${classes.item} ${classes.item7}`}><img src="/partners/cityprocar.svg" className="h-[80%]" /></div>
          <div className={`${classes.item} ${classes.item8}`}><img src="/partners/zokiph.jpg" className="h-[80%]" /></div>
          <div className={`${classes.item} ${classes.item9}`}><img src="/partners/titanium.svg" className="h-[80%]" /></div>
          <div className={`${classes.item} ${classes.item10}`}><img src="/partners/hotheels.jpg" className="h-[80%]" /></div>
          <div className={`${classes.item} ${classes.item11}`}><img src="/partners/navak.jpg" className="h-[80%]" /></div>
          <div className={`${classes.item} ${classes.item12}`}><img src="/partners/overboost.jpg" className="h-[80%]" /></div>
          <div className={`${classes.item} ${classes.item13}`}><img src="/partners/vagshop.jpg" className="h-[80%]" /></div>
          <div className={`${classes.item} ${classes.item14}`}><img src="/partners/vagclubserbia.jpg" className="h-[80%]" /></div>
          <div className={`${classes.item} ${classes.item15}`}><img src="/partners/megalight.png" className="h-[80%]" /></div>
          <div className={`${classes.item} ${classes.item16}`}><img src="/partners/maliserviser.jpg" className="h-[80%]" /></div>
          <div className={`${classes.item} ${classes.item17}`}><img src="/partners/simmaster.jpg" className="h-[80%]" /></div>
          <div className={`${classes.item} ${classes.item18}`}><img src="/partners/garage16.png" className="h-[80%]" /></div>
          <div className={`${classes.item} ${classes.item19}`}><img src="/partners/tpcvolana.jpg" className="h-[80%]" /></div>
          <div className={`${classes.item} ${classes.item20}`}><img src="/partners/xenoncarbon.jpg" className="h-[80%]" /></div>
          <div className={`${classes.item} ${classes.item21}`}><img src="/partners/budzevina.jpg" className="h-[80%]" /></div>
          <div className={`${classes.item} ${classes.item22}`}><img src="/partners/mnfloring.png" className="h-[80%]" /></div>
          <div className={`${classes.item} ${classes.item23}`}><img src="/partners/bmwserbia.png" className="h-[80%]" /></div>
          <div className={`${classes.item} ${classes.item24}`}><img src="/partners/forzatuning.svg" className="h-[80%]" /></div>
        </div>
    </div>
  )
}

export default Partners