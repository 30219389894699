import video from '../assets/LIAR_FINAL.MP4';

const Hero = () => {
    return (
        <div>
            <div className="w-full h-[500px] bg-hero bg-cover bg-center flex items-center justify-center" id="hero">
                <div className="absolute w-full h-[500px] opacity-50">
                    <video
                        className="w-full h-[500px] object-cover"
                        src={video}
                        autoPlay
                        loop
                        playsInline
                        muted />
                </div>
                <div className="flex flex-col gap-3 items-center justify-center w-full h-full z-[1] bg-gradient-to-b from-[#18181811] via-[#18181899] to-[#18181811]">
                    <h1 className="text-white text-md drop-shadow-lg font-corporate small-caps font-medium tracking-[20px] mr-[-20px] sm:tracking-[35px] sm:mr-[-35px]">welcome to</h1>
                    <h1 className="text-white text-5xl drop-shadow-lg font-corporate small-caps font-semibold tracking-[15px] mr-[-15px]">belts shop</h1>
                    <hr className="w-[300px] opacity-20" />
                    <h1 className="text-white text-md drop-shadow-lg font-corporate small-caps font-medium tracking-[10px] mr-[-10px]">autopojasevi.rs</h1>
                </div>
            </div>
        </div>
    )
}

export default Hero;